<template>
  <div class="question">
    <div class="main-container">
      <section class="app-main">
        <div class="container" ref="container">
          <div class="main-background">
            <img
                style="width: 100%; height: 100%; object-fit: cover"
                :src="question['bg_img']"
            />
          </div>
          <div class="title">
            <span style="font-size: 1.8rem">{{ question["name"] || "-" }}</span>
            <span class="ct">答题倒计时：</span>
            <span>{{ timeLable }}</span>
          </div>

          <!--  -->
          <div class="main">
            <div class="sub-title">
              <span style="font-size: 1.5rem">{{
                  topic[activeKey]["name"]
                }}</span>
              <div class="progress">
                <i class="el-icon-time"></i>
                <span>当前进度:</span>
                <el-progress
                    :percentage="percentage_method"
                    :color="customColor"
                    style="width: 10rem"
                ></el-progress>
              </div>
            </div>
            <!-- 放大镜 -->
            <div
                class="zoom"
                v-for="(item, index) in topic[activeKey]['zoomIn']"
                :key="index"
                :style="{
                left: item['left'],
                top: item['top'],
              }"
            >
              <img
                  :src="require('@/assets/img/zoomIn.png')"
                  @click="showKeyWords(item['modalType'])"
              />
            </div>
            <div class="choose" v-if="topic[activeKey]['choose'] != undefined">
              <div class="choose-title">
                {{ topic[activeKey]["choose"]["title"] }}
              </div>
              <!-- 多选 -->
              <div
                  class="choose-option"
                  v-if="topic[activeKey]['choose']['type'] == 'check'"
              >
                <el-checkbox-group v-model="ckeckList">
                  <div
                      class="option"
                      v-for="(item, index) in topic[activeKey]['choose'][
                      'chooseOption'
                    ]"
                      :key="index"
                  >
                    <el-checkbox :label="item.value">{{
                        item.name
                      }}</el-checkbox>
                  </div>
                </el-checkbox-group>
              </div>

              <!-- 单选 -->
              <div
                  class="choose-option"
                  v-if="topic[activeKey]['choose']['type'] == 'radio'"
              >
                <el-radio-group v-model="radio">
                  <div
                      class="option"
                      v-for="(item, index) in topic[activeKey]['choose'][
                      'chooseOption'
                    ]"
                      :key="index"
                  >
                    <el-radio :label="item.value">{{ item.name }}</el-radio>
                  </div>
                </el-radio-group>
              </div>
            </div>
          </div>

          <!-- 按钮栏  -->
          <div class="toolbar">
            <div class="toolbar-content">
              <div class="btns">
                <el-button type="primary tips" v-if="type != 'opera'" @click="onTips">提示</el-button>

                <div class="right-btn">
                  <el-button
                      type="primary"
                      @click="onSubmit"
                      :disabled="subDisabled"
                  >提交</el-button
                  >
                  <el-button type="primary" @click="reset">重做</el-button>
                  <el-button type="primary" @click="goBack">返回</el-button>
                </div>
              </div>
            </div>
          </div>

          <!-- 弹出框 -->
          <keyWords
              ref="keyWord"
              :visible="keyVisible"
              :turn="turn"
              :print="print"
              :move='move'
              :keywordOption="topic[activeKey]['keywords']"
              :close="() => (keyVisible = false)"
          ></keyWords>
          <wind-room
              ref="wind"
              :visible="windVisible"
              :turn="turn"
              :move="move"
              :auto="auto"
              :keywordOption="topic[activeKey]['keywords']"
              :close="() => (windVisible = false)"
          ></wind-room>
          <front-room
              ref="front"
              :visible="frontVisible"
              :stop="stop"
              :trans="trans"
              :keywordOption="topic[activeKey]['keywords']"
              :close="() => (frontVisible = false)"
              v-on:turnClose="turnClose"
          ></front-room>
          <room
              ref="room"
              :visible="roomVisible"
              :tool="tool"
              :stop="stop"
              :trans="trans"
              :keywordOption="topic[activeKey]['keywords']"
              :close="() => (roomVisible = false)"
          ></room>

          <tips
              :visible="tipsVisible"
              :tips="topic[activeKey]['tips']"
              :close="() => (tipsVisible = false)"
          ></tips>
          <!--  -->
          <tabs
              ref="tabs"
              v-if="activeKey <= 3"
              :visible="keyVisible"
              :urls="urls"
              v-on:aa="changebg"
          ></tabs>

          <tools
              ref="tools"
              v-if="activeKey == 3&&roomVisible"
              :visible="keyVisible"
              :urls="urls"
              v-on:selectTool="selectTool"
          ></tools>
          <window
              ref="window"
              :visible="windowVisible"
              :open="open"
              :close="() => (windowVisible = false)"
              v-on:open="openWindow"
          ></window>
          <smoke
              :visible="smokeVisible"
              :urls="urls"
              :close="() => (smokeVisible = false)"
              v-on:upDown="upDown"
          ></smoke>

          <div v-if="activeKey >= 4" class="plugins">
            <div
                style="
                top: 37.802%;
                left: 19.4%;
                height: 25.37%;
                width: 16.3%;
                position: absolute;
              "
            >
              <div class="windows-comp" result="" scence-idx="0">
                <div class="glass-comp" :class="{ open: open }">
                  <div class="window-frame-comp">
                    <div class="window-comp window-cover-comp">
                      <div class="window-face-0-comp"></div>
                      <div class="window-face-2-comp"></div>
                      <div class="window-face-1-comp"></div>
                      <div class="window-face-3-comp"></div>
                      <div class="window-face-4-comp"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
                style="
                top: 8.73913%;
                left: 47.6%;
                height: 5.13834%;
                width: 37.86%;
                position: absolute;
              "
            >
              <div
                  class="screen-comp"
                  :class="{ open: down }"
                  result=""
                  scence-idx="0"
              ></div>
            </div>
          </div>
          <div class="fullscreen" style="position: absolute;bottom: -3.125rem;" v-if="!isFullscreen" @click="fullscreenClick">进入全屏</div>
        </div>
      </section>
    </div>

    <!--音频  -->
    <audio
        v-show="false"
        ref="audio"
        controls
        src=""
        preload="metadata"
        @play="handleMusicPlay"
        @ended="handleMusicEnded"
    >
      <source src="" />
    </audio>
    <!--   音频2 按钮、放大、放小-->
    <audio v-show="false" ref="audio2" controls src="" preload="metadata">
      <source src="" />
    </audio>
    <!--    音频3 需要一直循环的声音-->
    <audio v-show="false" ref="audio3" controls src="" preload="metadata">
      <source src="" />
    </audio>
    <Baywindow></Baywindow>
  </div>
</template>

<script>
import keyWords from "@/component/keyWords.vue";
import tips from "@/component/tips.vue";
import Tabs from "@/component/tabs";
import windRoom from "@/component/windRoom";
import frontRoom from "@/component/frontRoom";
import room from "@/component/room";
import tools from "@/component/tools";
import window from "@/component/window";
import smoke from "@/component/smoke";
import screenfull from "screenfull";
import { arraysAreEqual,fomatTime  } from "../../util/arr.js";
import Baywindow from "@/components/Baywindow.vue";

export default {
  data() {
    return {
      isFullscreen: true,
      fullscreenFlag: false,
      question: null,
      // 进度条
      percentage: 20,
      customColor: "#409eff",
      customColors: [{ color: "#f56c6c", percentage: 20 }],
      keyVisible: false,
      windVisible: false,
      frontVisible: false,
      tipsVisible: false,
      roomVisible: false,
      windowVisible: false,
      smokeVisible: false,
      cities: [],
      topic: [],
      ckeckList: [],
      radio: null,
      activeKey: 0,
      // 答案集合
      ansObj: {},
      urls: [],
      trans: false,
      turn: false,
      down: false,
      open: false,
      move: false,
      auto: true,
      stop: false,
      tool: 0,
      subDisabled: true, //提交按钮, 是否点击
      print: false,
      type:'viewr',
      timer:null,
      timeLable:'00:00',
      count:0,
      randomNum:0,
      apiId:'10007',
      totalScore:0,
    };
  },
  components: {
    Tabs,
    keyWords,
    tips,
    windRoom,
    frontRoom,
    room,
    tools,
    window,
    smoke,
    Baywindow
  },
  computed: {
    percentage_method() {
      return parseInt(this.percentage * (this.activeKey + 1));
    },
  },
  created() {
    this.type = this.$route.query.type
    this.count = this.$route.query.count
    let { question } = require(`@/api/wei${this.apiId}`);
    this.question = question;
    this.topic = question["topic"];
    this.play(this.topic[0]["audio"]);
    this.urls = question["tab"].filter((value, index, array) => {
      if (this.topic[0]["tab"].indexOf(index) >= 0) {
        return array[index];
      }
    });
    this.question["bg_img"] = this.urls[0].src;
    this.topic[this.activeKey]["zoomIn"] = this.urls[0].zoomIn;
    this.percentage = 100 / this.topic.length;

    screenfull.toggle()
    this.type = this.$route.query.type
    this.count = this.$route.query.count
    if(this.type !== 'viewr'){
      this.timeCountSetInterVal()
    }
  },
  mounted(){
    // 监听全屏变化
    screenfull.onchange(() => {
      this.isFullscreen = screenfull.isFullscreen
    })
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.timer);
    this.timer=null;
    next(true);
  },
  methods: {
    fullscreenClick(){// 手动全屏
      screenfull.toggle();
    },
    timeCountSetInterVal(){// 考试模式计时器
      if(this.timer) return
      this.timer = setInterval(() => {
        this.count = this.count - 1
        this.timeLable=fomatTime(this.count)
        if(this.count <= 0){
          clearInterval(this.timer)
          this.timer = null

          let ids = sessionStorage.getItem('wids') ? JSON.parse(sessionStorage.getItem('wids')) : []
          ids.push(this.apiId)
          sessionStorage.setItem('wids',JSON.stringify(ids))
          sessionStorage.setItem("topic", JSON.stringify(this.topic));
          sessionStorage.setItem("ansObj", JSON.stringify(this.ansObj));
          this.$router.replace("/result");
          this.reset();
        }
      },1000)
    },
    turnClose() {
      this.turn = this.$refs.front.turn;
      if (this.turn) {

      }else{
        this.$refs.audio2.pause();
        this.$refs.audio3.pause();
      }
    },
    selectTool() {
      this.tool = this.$refs.tools.tool;

    },
    changebg(url) {
      this.question["bg_img"] = url.src;
      this.topic[this.activeKey]["zoomIn"] = url.zoomIn;
      this.trans = this.$refs.wind.trans;
      this.stop = this.$refs.wind.stop;
      this.turn = this.$refs.front.turn;
      this.auto = this.$refs.keyWord.auto;
      this.move = this.$refs.room.move;
      this.print = this.move || this.turn;
      if(this.activeKey==2){
        this.$refs.wind.stop=false;
      }
    },
    openWindow(val) {
      if (val == 0) {
        this.open = true;
      }
      if (val == 2) {
        this.open = false;
      }
    },
    upDown(val) {
      if (val == "down") {
        this.down = true;
      } else {
        this.down = false;
      }
      this.play2('12-6-2挡烟垂壁上升声.mp3')
    },
    async play(val) {
      if (!val) return;
      let music = new Audio();
      // 这里获取播放路径
      music = require(`@/assets/audio/${val}`);

      this.$nextTick((res) => {
        this.$refs.audio.src = music;
        let audio = this.$refs.audio;
        audio.load();
        audio.play();
      });
    },
    play2(val) {
      if (!val) return;
      let music = new Audio();
      // 这里获取播放路径
      music = require(`@/assets/audio/${val}`);

      this.$nextTick((res) => {
        this.$refs.audio2.src = music;
        let audio = this.$refs.audio2;
        audio.load();
        audio.play();
      });
    },
    play3(val, loop = false, play = "play") {
      if (play === "stop") {
        this.$refs.audio3.pause();
      }
      if (!val) return;
      let music = new Audio();
      // 这里获取播放路径
      music = require(`@/assets/audio/${val}`);

      this.$nextTick((res) => {
        this.$refs.audio3.src = music;
        let audio = this.$refs.audio3;
        audio.loop = loop;
        audio.load();
        audio.play();

      });
    },

    handleMusicPlay() {
      this.subDisabled = true;
    },
    handleMusicEnded() {
      this.subDisabled = false;
    },
    goBack() {
      this.$router.go(-1);
    },
    showKeyWords(type) {
      if (type == "keyCode") {
        this.keyVisible = true;
      }
      if (type == "windRoom") {
        this.windVisible = true;
      }
      if (type == "frontRoom") {
        this.frontVisible = true;
      }
      if (type == "room") {
        this.roomVisible = true;
      }
      if (type == "window") {
        this.windowVisible = true;
      }
      if (type == "smoke") {
        this.smokeVisible = true;
      }
      this.play2("zoomout.mp3");
    },
    onTips() {
      this.tipsVisible = !this.tipsVisible;
      this.play2("zoomout.mp3");
    },
    onSubmit() {
      console.log(this.print)
      if (this.activeKey == this.topic.length) return;
      // 多选
      if (this.question.topic[this.activeKey]["type"] == "check") {
        if (this.ckeckList.length == 0) return;
        // 判断两个数组全等 
      }
      // 单选 
      if (this.question.topic[this.activeKey]["type"] == "radio") {

      }
      let res = false;
      switch (this.activeKey) {
        case 0:
          if (this.turn && this.radio == 1) {
            res = true;
          }
          this.turn = true;
          this.auto = true;
          break;
        case 1:
          if (!this.turn && this.stop) {
            res = true;
          }
          this.$refs.wind.trans = true;
          break;
        case 2:
          if (this.move) {
            res = true;
          }
          this.stop = false;
          this.$refs.room.move = true;
          
          break;
        case 3:
          this.move = this.$refs.room.move;
          if (!this.move) {
            res = true;
          }
          break;
        case 4:
          if ((this.open && !this.randomNum)||(!this.open&&this.randomNum)) {
            res = true;
          }

          break;
        case 5:
          if ((this.down && !this.randomNum)||(!this.down&&this.randomNum)){
            res = true;
          }
          break;
      }

      this.ansObj[this.activeKey] = res;

      if(this.ansObj[this.activeKey]){
        this.totalScore+=parseFloat(this.topic[this.activeKey]['score'])
      }
      let sorceObj = sessionStorage.getItem("sorceObj")? JSON.parse(sessionStorage.getItem("sorceObj")):[];
      sorceObj[this.apiId] = this.totalScore
      sessionStorage.setItem("sorceObj",JSON.stringify(sorceObj));
      console.log('分数',sorceObj)

      this.play2("select.mp3");

      if (this.activeKey < this.question.topic.length - 1) {
        this.activeKey = this.activeKey + 1;
        this.play(this.topic[this.activeKey][["audio"]]);
        this.play3(this.topic[this.activeKey][["loopAudio"]]);
        this.randomNum = this.topic[this.activeKey]["randomNum"];
        if (this.activeKey == 4){
          this.open = this.topic[4]["randomNum"]==0?false:true;
          if(this.open){
            this.$refs.window.i=0;
          }
        }
        if (this.activeKey == 5){
          this.down = this.topic[5]["randomNum"]==0?false:true;
          
        }

        //this.question['bg_img'] = url.src
        if (this.activeKey < 4) {
          this.urls = this.question["tab"].filter((value, index, array) => {
            if (this.topic[this.activeKey]["tab"].indexOf(index) >= 0) {
              return array[index];
            }
          });
          this.question["bg_img"] = this.urls[0].src;
          this.$refs.tabs.selected = 0
          this.topic[this.activeKey]["zoomIn"] = this.urls[0].zoomIn;
        } else {
          this.question["bg_img"] = this.topic[this.activeKey].background;
        }
      } else {
        sessionStorage.setItem("topic", JSON.stringify(this.topic));
        sessionStorage.setItem("ansObj", JSON.stringify(this.ansObj));
        let ids = sessionStorage.getItem('wids') ? JSON.parse(sessionStorage.getItem('wids')) : []
        ids.push(this.apiId)
        sessionStorage.setItem('wids',JSON.stringify(ids))
        setTimeout(() => {
          this.$router.replace("/result");
        }, 500);
        return true;
      }
      this.reset();
    },
    reset() {
      this.ckeckList = [];
      this.radio = null;
      // this.print = false
      this.tipsVisible=false;
      this.keyVisible = false;
      this.windVisible = false;
      this.frontVisible = false;
      this.roomVisible = false;
      this.windowVisible = false;
      this.smokeVisible = false;
      this.$refs.audio3.pause();
    },
    resetAll(){

      this.$refs.room.round = false
      this.$refs.room.halfround = false
      this.$refs.room.wrench1 = false
      this.$refs.room.wrench = false
      this.$refs.wind.trans = false
      this.$refs.wind.stop = false
      this.$refs.front.open = false
      this.$refs.front.turn = false
      this.$refs.front.inside = false
    },

    //
    // // 全面屏

    fullscreen() {
      // 需要全屏显示的dom元素
      let dom = this.$el.querySelector(".container");
      // 调用全屏方法
      this.$fullscreen.enter(dom, {
        wrap: false,
        callback: (f) => {
          this.fullscreenFlag = f;
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.container .toolbar {
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 2.5rem;
  z-index: 9999;
}
.screen-comp.close {
  height: 2rem;
}
.screen-comp {
  background: url("https://bankaoedu.com/files/xc/chuibi-2.png") no-repeat;
  background-size: 100% auto;
  background-position: bottom;
  -webkit-transition: height 5s linear;
  transition: height 5s linear;
  height: 2rem;
}
.screen-comp.open {
  height: 15.75rem;
}
.open .window-cover-comp {
  -webkit-transform: rotateX(-45deg);
  transform: rotateX(-45deg);
}
.windows-comp .glass-comp,
.windows-comp {
  height: 100%;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.windows-comp {
  position: absolute;
  top: 0;
  left: 0;
}
.windows-comp .glass-comp {
  position: relative;
}
.windows-comp .glass-comp,
.windows-comp {
  height: 100%;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.windows-comp .glass-comp div {
  position: absolute;
}
.window-frame-comp {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-perspective: 37.5rem;
  perspective: 37.5rem;
}
.windows-comp .glass-comp div {
  position: absolute;
}
.window-cover-comp {
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center;
  bottom: 0;
}
.window-comp {
  width: 100%;
  top: 0;
  bottom: 0;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  -webkit-transition: -webkit-transform 6s;
  transition: -webkit-transform 6s;
  transition: transform 6s;
  transition: transform 6s, -webkit-transform 6s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 9999;
  -webkit-perspective: 800px;
  perspective: 800px;
  -webkit-box-shadow: inset -5px -5px 5px #000;
  box-shadow: inset -5px -5px 5px #000;
}
.windows-comp .glass-comp div {
  position: absolute;
}
.window-face-0-comp {
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-transform: translateZ(-0.5rem);
  transform: translateZ(-0.5rem);
}
.window-face-0-comp,
.window-face-1-comp {
  left: -0.1875rem;
  top: -0.1875rem;
  right: -0.1875rem;
  bottom: -0.1875rem;
  border: 0.6rem solid #636e77;
  background-color: rgba(149, 227, 187, 0.1);
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
  border-radius: 0;
}
.windows-comp .glass-comp div {
  position: absolute;
}
.window-face-2-comp {
  height: 0.5rem;
  left: -0.1875rem;
  right: -0.1875rem;
  top: 0;
  border: 0.1875rem solid #636e77;
  border-radius: 0;
  background-color: #636e77;
  -webkit-transform: translate3D(0, -0.4688rem, -0.1875rem) rotateX(90deg);
  transform: translate3D(0, -0.4688rem, -0.1875rem) rotateX(90deg);
}
.windows-comp .glass-comp div {
  position: absolute;
}
.window-face-1-comp {
  -webkit-transform: translateZ(0);
  transform: translateZ(-35);
}
.window-face-0-comp,
.window-face-1-comp {
  left: -0.1875rem;
  top: -0.1875rem;
  right: -0.1875rem;
  bottom: -0.1875rem;
  border: 0.6rem solid #636e77;
  background-color: rgba(149, 227, 187, 0.1);
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
  border-radius: 0;
}
.windows-comp .glass-comp div {
  position: absolute;
}
.window-face-3-comp {
  width: 0.375rem;
  top: -0.1875rem;
  right: 0;
  bottom: -0.1875rem;
  border: 0.1875rem solid #636e77;
  border-radius: 0;
  background-color: #636e77;
  -webkit-transform: translate3D(0.4688rem, 0, -0.1875rem) rotateY(90deg);
  transform: translate3D(0.4688rem, 0, -0.1875rem) rotateY(90deg);
}
.windows-comp .glass-comp div {
  position: absolute;
}
.window-face-4-comp {
  height: 0.375rem;
  left: -0.1875rem;
  right: -0.1875rem;
  bottom: 0;
  border: 0.1875rem solid #636e77;
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-transform: translate3D(0, 0.4688rem, -0.1875rem) rotateX(90deg);
  transform: translate3D(0, 0.4688rem, -0.1875rem) rotateX(90deg);
}

.question {
  position: relative;
  height: 100%;
  width: 100%;
  // ======
}
// .main-container {
//   width: 100%;
//   height: 100%;
//   transition: margin-left 0.28s;
//   margin-left: 0;
//   position: relative;
//   background: #f8f8f8;
// }
.main-container {
  min-height: 100%;
  transition: margin-left 0.28s;
  margin-left: 0;
  position: relative;
  background: #f8f8f8;
}
.app-main {
  position: relative;
}

.container {
  // 全屏下
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
  position: relative;
  // 非全屏下
  //   width: 120rem;
  // height: 67.5rem;
  // margin: 0 auto;
  // position: relative;

  .main-background {
    position: absolute;
    inset: 0 0 0 0;
    z-index: 0;
    overflow: hidden;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    // width: 100%;
    // height: 100%;
  }
  .title {
    height: 2.5rem;
    background-color: #409eff;
    line-height: 2.5rem;
    color: #fff;
    font-weight: 500;
    padding: 0 1.25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    z-index: 8;
    left: 0;
    top: 0;
    width: 100%;
    font-size: 2rem;
    .ct {
      margin-left: auto;
    }
  }
  .main {
    position: relative;
    height: 100%;
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    box-sizing: border-box;
    background-attachment: fixed;
    .zoom {
      position: absolute;
      cursor: pointer;
      img {
        width: 2.5rem;
        height: 2.5rem;
      }
    }
    .sub-title {
      height: 2.5rem;
      background-color: #fff;
      padding: 0 1.25rem;
      position: absolute;
      z-index: 8;
      left: 0;
      top: 2.5rem;
      width: 100%;
      font-size: 1.5rem;
      .progress {
        font-size: 0.875rem;
        span {
          font-size: 0.8125rem;
          margin: 0 0.3125rem;
        }
      }
    }
    // 选这题
    .choose {
      position: absolute;
      left: 1.25rem;
      top: 6.25rem;
      min-width: 12.5rem;
      max-width: 21.875rem;
      background-color: #409eff;
      z-index: 99999;
      border-radius: 0.625rem;
      box-shadow: 1px 1px 5px #ccc;
      box-sizing: border-box;
      .choose-title {
        color: #fff;
        padding: 0.625rem;
        font-size: 1.5rem;
      }
      .choose-option {
        background-color: #fff;
        padding: 1.25rem 0.625rem;
        border-radius: 0 0 0.625rem 0.625rem;
        .option {
          margin-bottom: 1.25rem;
          font-size: 1.5rem;
        }
      }
    }
  }

  //   按钮
  .toolba {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 2.5rem;
    z-index: 9999;
  }
  .toolbar-content {
    margin: 0 12.5rem;
    .right-btn {
      margin-left: auto;
    }
    button {
      padding: 0.625rem 1.875rem;
      color: #fff;
      font-size: 16px;
      margin: 0 1.875rem;
      border-radius: 0.3125rem;
      cursor: pointer;
    }
  }
  .toolbar-content .btns,
  .toolbar-content .right-btn {
    display: flex;
    align-items: center;
  }
  .toolbar-content .btns {
    position: absolute;
    z-index: 9999;
    left: 6.25rem;
    right: 6.25rem;
    bottom: 0;
  }
}
.container .main .sub-title .progress,
.container .main .sub-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

// 屏幕
</style>
